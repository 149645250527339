/*
 * This file contains Tímavera's modifications to the Vitlity Red Bootstrap
 * theme (vitality-red.css). The theme has served us well but every now and
 * then we bump into an area where it either doesn't have the desired look we
 * want or it exhibits a weird behaviour.
 */

/*
 * Temporary section spacing until the project has been migrated to Next.js and
 * a new front page navigation header instead of the old Bootstrap one. The new
 * one in NavigationFront.js did not play well with CRA. Frontpage styles were
 * leaking onto the dashboard. With Next.js there will be better tools at our
 * disposal to prevent that.
 */
section {
  padding: 50px 0;
}

@media (max-width: 768px) {
  section {
    padding: 35px 0;
  }
}

/*
 * The Vitality theme originally only had bg-gray and bg-dark background
 * classes. At the time of writing the website is by default bg-dark and then
 * we specify which sections we want to have a white background. This is a hack
 * so our page footers don't look weird (white below black).
 */
.bg-white {
  color: #333;
  background-color: white;
}

/*
 * Makes the ≡ mobile menu stripes black when we hover over it or when it's
 * active. Without this the navigation toggle just becomes all white when it
 * has focus or is being hovered (confusing).
 */
.navbar-inverse .navbar-toggle:hover .icon-bar,
.navbar-inverse .navbar-toggle:focus .icon-bar {
  background-color: black;
}

/*
 * Makes the xXx middle pricing panel overflow work on Bootstrap's small screen
 * breakpoint. The code below is a copy paste from the original Vitality Red
 * theme. The only change is the "min-width: 768px" instead of 992px. I add
 * this overwritten behaviour here because the pricing panels are pretty small
 * in content so it works. But if the content in them grows for example if were
 * to add feature lists then it would be better to remove this custom style and
 * start using the default one that works on in the medium screen breakpoint.
 */
@media (min-width: 768px) {
  .pricing {
    background-attachment: scroll;
  }
  .pricing .pricing-item {
    margin-bottom: 0;
  }
  .pricing .pricing-item.featured-first {
    margin-right: -15px;
    margin-top: 15px;
  }
  .pricing .pricing-item.featured {
    position: absolute;
    width: 100%;
    left: 0;
    margin: 0;
    padding: 45px 30px;
  }
  .pricing .pricing-item.featured-last {
    margin-left: -15px;
    margin-top: 15px;
  }
}

/*
 * A custom style for an active navigation item. The default class is named
 * "active" and in the theme is makes items look like white buttons with red
 * text. It is a little bit too aggressive. The style below is a bit more
 * subtle, just a thick line under the currently active page.
 */
.activeNav {
  border-bottom: 0.25em solid #fff;
}

/* Makes the NavItem on the dashboard look like tabs when selected */
.activeNavTab {
  background-color: white;
  padding-bottom: 5px;
}

.activeNavTab a {
  color: #333 !important;
}

/* Custom style for the the navigation tab bar on the dashboard */
.tabNavBar {
  padding-bottom: 0;
  padding-top: 15px;
  border: none;
  border-bottom: 5px solid #fff;
}

@media (max-width: 768px) {
  /* Do not show the tab nav bar spacing border on mobile */
  .tabNavBar {
    border: none;
  }
}

/*
 * For some reason the h2 size in the Vitality theme is 50px where the h1 is
 * 52px. So essentially the same. It is way too big. Made it 42px instead which
 * is about 80% of h1 of 52px. ptc = privacy terms cookies. Only applies to
 * those pages for now.
 */
.ptc h2 {
  font-size: 30px;
  margin-top: 2em;
  margin-bottom: 0.5em;
}

/*
 * By default form text inputs have the red theme as border on focus. Not ideal
 * because it could be confused with a validation error. Make it just black
 * instead and reserve the red border for actual validation errors.
 */
input.form-control:focus {
  border-color: #000; /* Original: #ef4035 */
}

/** Makes input validation clearer on dark backgrounds, defaults assume white */
.bg-dark .has-error .control-label,
.bg-dark .has-error .help-block,
.bg-dark .has-error .form-control-feedback {
  color: #ff4d4d;
}
.bg-dark .has-error .form-control {
  border-color: #ff4d4d;
  border-width: 2px;
}
.bg-dark .has-error .help-block {
  font-weight: 500;
}
