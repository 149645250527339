/*
  Global styles. Ideally we shouldn't write anything here, instead we should
  try to create style.css files alongside our react components and pages.
  However this is an escape hatch if we should ever need it.
*/

/* Adds margins to printed pages. Without this printers will cut off our text. */
@media print {
  html,
  body {
    /*
      Gets rid of extra pages when printing out. The Vitality theme sets a
      `html, body { height: 100% }` in vitality-red.css. Not sure why. At the
      time of writing we use the third party library react-to-print to print
      tables. The component inherits all styles, including this 100% height
      property which creates an empty page and the end when printing reports.
    */
    height: unset;
  }

  @page {
    margin: 20mm; /* margins on a physical page when printing */
  }

  /**
   * Better printout for projects and employees summary report tables.
   * Bootstrap has by default a style that adds the relative URL to in brackets
   * behind <a> elements. For example: "Project A (/app#project-0)". This
   * removes the (/app#project-0) bit on the project and employee names if the
   * summary report tables are printed.
   */
  a.hidden-print-link[href]::after {
    content: none !important;
  }
}

body {
  background-color: #222;
}

/**
 * Make the background of the body tag white if it contains a descendant with
 * the class "onboarding". At the time of writing has 84% global support. It is
 * not a deal breaker if it is not supported by a browser, will just not look
 * as good (split background).
 */
body:has(.onboarding) {
  background-color: #fff;
}

/** Same as above but applied also to admin */
body:has(.admin) {
  background-color: #fff;
}

/* Makes the navigation bars partially transparent and blurry */
.seeThroughNavBar {
  backdrop-filter: blur(5px);
  background-color: rgba(34, 34, 34, 0.95);
}

html {
  /**
   * Offset for the height of the static header the page has. Makes the content
   * be correctly in view when clicking anchor links like on the front page and
   * in reports. If not specified the content will be the top of the page which
   * hides the first bit of the content (underneath the header). At the time of
   * writing the header is actually 75px so 85px gives an nice 10px extra space
   * instead of appearing right underneath the header.
   */
  scroll-padding-top: 85px;

  /* Fast but smooth scroll on anchor links instead of a jarring jump */
  scroll-behavior: smooth;
}

/** Optional style for the last row in tables to show e.g. sum totals */
.tableTotalRow {
  font-weight: bold;
  border-top: 2px solid #dddddd;
  background-color: #eee;
}
